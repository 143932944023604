import React from 'react'
import { Pressable, View, Text } from 'react-native'

import style from './style'
import { Input } from '../../Input/Input'
import { NavBarProps } from './interface'
import { Size } from '../../../utils/constants'

/**
 * @deprecated Since version 1.1.2. Please use TopNavigationBar instead. Will be removed in next release
 */
class NavBar extends React.Component<NavBarProps> {
    renderButtonName = ({ name, onPress }) => {
        return (
            <Pressable key={name} onPress={onPress}>
                <Text
                    style={[
                        style.navButtonNameStyle,
                        this.props.buttonTextStyle,
                    ]}
                >
                    {name}
                </Text>
            </Pressable>
        )
    }

    render() {
        const {
            headerLogoImage,
            inputBox = true,
            inputBoxPlaceholder = 'search',
            inputIcon,
            navRightIcon,
            cartItem = false,
            cartItemCount,
            navSecondRightIcon,
            userIcon,
            loginIcon = true,
            avatarIcon,
            navButtons,
            containerStyle,
            loginText,
            loginTextStyle,
        } = this.props

        return (
            <View style={[style.contanier, containerStyle]}>
                <View style={style.flexStyle}>
                    {headerLogoImage}
                    <View style={style.flexStyle}>
                        {navButtons?.map(this.renderButtonName)}
                    </View>
                </View>
                <View>
                    {inputBox && (
                        <Input
                            placeholder={inputBoxPlaceholder}
                            leftIcon={inputIcon}
                            size={Size.Medium}
                            style={style.inputStyle}
                        />
                    )}
                </View>
                <View style={style.flexStyle}>
                    {loginIcon ? (
                        <View style={style.flexStyle}>
                            {userIcon}
                            <Text style={[style.loginText, loginTextStyle]}>
                                {loginText}
                            </Text>
                        </View>
                    ) : (
                        avatarIcon
                    )}
                    {navSecondRightIcon && <View>{navSecondRightIcon}</View>}
                    {navRightIcon && (
                        <View>
                            {navRightIcon}
                            {cartItem ? (
                                <View style={style.cartStyle}>
                                    <Text style={style.cartText}>
                                        {cartItemCount}
                                    </Text>
                                </View>
                            ) : null}
                        </View>
                    )}
                </View>
            </View>
        )
    }
}

export default NavBar
