const STRINGS_TESTCASE = {
    // string test case for button
    BUTTON_COMPONENT: 'Button Component On Press',
    buttonComponent: 'Button Component',
    buttonRendersWithoutCrashing: 'Button Renders Without Crashing',
    buttonOnPress: 'Button On Press',
    buttonGetSize: 'Button Get Size',
    buttonGetBackgroundColor: 'Button Get Background Color',
    buttonGetTextColor: 'Button Get Text Color',

    // string test case for segmented control
    SEGMENTCONTROL_COMPONENT: 'segmented Control Component list',
    segmentedCtrlComponent: 'Segmented Control Component',
    sgmtCtrlRendersWithoutCrashing: 'Segmented Ctrl Renders Without Crashing',
    sgmtCtrlOnPress: 'Segmented Ctrl On Press',

    // string test case for popover
    POPOVER_COMPONENT_BTN: 'Popover Compt Button On Press',
    POPOVER_COMPONENT_ICON: 'Popover Compt Icon On Press',
    popoverCtrlComponent: 'Popover Control Component',
    popoverRendersWithoutCrashing: 'Popover Renders Without Crashing',
    popoverOnPress: 'Popover On Press',

    popoverInputComponent: 'Popover Input Component',
    POPOVER_INPUT_COMPONENT_ICON: 'popover Input Compt Icon On Press',
    POPOVER_INPUT_COMPONENT_CANCEL: 'popover Input Compt Cancel On Press',
    POPOVER_INPUT_COMPONENT_DONE: 'popover Input Compt Done On Press',

    //string test case for Cards..
    cardsComponent: 'Cards Component',
    cardRendersWithoutCrashing: 'Card Renders Without Crashing',

    // string test case for navbar-component
    headerLogoText: 'headerLogoText',
    cartItemCount: 'cartItemCount',
    rightIcon: 'right_Icon',

    //string test case for chips..
    chipsComponent: 'Chips Component',
    chipsRendersWithoutCrashing: 'Chips Renders Without Crashing',
    chipsOnPress: 'Chips On Press',
    CHIPS_COMPONENT: 'Chips Component On Press',

    //string test case for TextButton..
    textButtonComponent: 'Text Button Component',
    txtBtnRendersWithoutCrashing: 'Text Button Renders Without Crashing',
    textButtonOnPress: 'Text Button On Press',
    TEXT_BUTTON_COMPONENT: 'Text Button Component On Press',
}
export default STRINGS_TESTCASE
