import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        width: 600,
        padding: 24,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        backgroundColor: COLORS.COLOR_WHITE,
        borderBottomEndRadius: 32,
        borderBottomLeftRadius: 8,
        flexDirection: 'row',
        position: 'absolute',
        bottom: 10,
    },
    leftButtonStyle: {
        display: 'flex',
        width: 120,
        height: 56,
        padding: 16,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        backgroundColor: COLORS.COLOR_SEGMNTD,
        marginRight: 16,
        shadowColor: 'rgba(0, 0, 0, 0.25)',
        shadowOffset: { width: -3, height: 4 },
        shadowOpacity: 0.2,
        alignSelf: 'center',
    },
    rightbtnStyle: {
        display: 'flex',
        width: 120,
        height: 56,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
    },
    rightBtnTextStyle: {
        fontSize: 16,
        fontWeight: '700',
    },
    leftBtnTextStyle: {
        fontSize: 16,
        fontWeight: '700',
        color: COLORS.COLOR_LIGHTBLUE,
    },
})
export default styles
