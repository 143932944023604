import { StyleSheet } from 'react-native'
import COLORS from '../../../utils/colors'
const hs = (value: number) => value
const ws = (value: number) => value
const ms = (value: number) => value

export const styles = StyleSheet.create({
    calanderPopUp: {
        position: 'absolute',
        left: 0,
        top: 0,
    },
    calendarContainer: {
        width: ws(364),
        backgroundColor: 'white',
        paddingVertical: hs(16),
        marginVertical: hs(4),
        borderRadius: ms(4),
        shadowOffset: { width: 2, height: 4 },
        shadowColor: COLORS.COLOR_SHADOW,
        shadowOpacity: 0.2,
        shadowRadius: 10,
        paddingHorizontal: 15,
    },
    todayContainer: {},
    todayText: {
        fontWeight: 'bold',
    },
    selectedContainer: {
        backgroundColor: '#F0E5FF',
    },
    selectedText: {
        color: '#6200EA',
    },
    placeholderContainer: {
        height: 44,
        flex: 1,
        backgroundColor: 'white',
        justifyContent: 'center',
        paddingHorizontal: ws(16),
        marginBottom: hs(2),
        borderWidth: ms(1),
        borderRadius: ms(8),
        borderColor: '#6200EA',
    },
    iconStyle: {
        height: '100%',
        width: '100%',
        resizeMode: 'stretch',
    },
    iconContainer: {
        height: 20,
        width: 20,
    },
})
