export enum Theme {
    Primary = 'primary',
    Secondary = 'secondary',
    Dark = 'dark',
}

export enum Size {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    Big = 'big',
    Min = 'min',
    Max = 'max',
}

export enum Type {
    Task = 'task',
    Event = 'event',
}

export enum Log {
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
}

// Below vals are being kept until full transformation
const CONSTANTS = {
    THEME_PRIMARY: 'primary',
    THEME_SECONDARY: 'secondary',
    THEME_DARK: 'dark',
    SIZE_SMALL: 'small',
    SIZE_MEDIUM: 'medium',
    SIZE_BIG: 'big',
    SIZE_LARGE: 'large',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
}

export default CONSTANTS
