import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
    mainView: {
        height: 50,
        backgroundColor: '#fff',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        top: 0,
        position: 'absolute',
    },
    shadowProp: {
        shadowOffset: { width: -2, height: 4 },
        shadowColor: COLORS.COLOR_SHADOW,
        shadowOpacity: 0.2,
        shadowRadius: 3,
        borderBottomRightRadius: 24,
    },
    headerText: {
        fontWeight: '700',
        fontSize: 14,
        color: COLORS.COLOR_HEADERTEXT,
        // fontFamily: 'Arial',
    },
    rightIcon: {
        height: 18,
        width: 18,
        tintColor: COLORS.COLOR_COOLGRAY,
    },
    leftIcon: {
        height: 18,
        width: 18,
        tintColor: COLORS.COLOR_COOLGRAY,
    },
    btnTextStyle: {
        // fontFamily: 'Inter-Medium',
        fontSize: 14,
        color: COLORS.COLOR_HIGHLIGHTBLUE,
    },
    leftIconView: {
        flex: 0.2,
        alignItems: 'flex-start',
        paddingLeft: 22,
    },
    rightIconView: {
        flex: 0.2,
        alignItems: 'flex-end',
        paddingRight: 22,
    },
    headerIcon: {
        height: 18,
        width: 18,
        tintColor: COLORS.COLOR_HEADERTEXT,
    },
    headerCenterView: {
        flex: 0.6,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    headerIconView: {
        marginRight: 5,
    },
})

export default styles
