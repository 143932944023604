import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

export const hs = (value: number) => value
export const ws = (value: number) => value
export const ms = (value: number) => value
export const wp = (value: any) => value

export const styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
        backgroundColor: 'white',
    },
    gradientContainer: {
        position: 'absolute',
        bottom: 0,
        height: hs(80),
        width: wp('100%'),
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dotContainer: {
        position: 'absolute',
        bottom: 0,
        flexDirection: 'row',
        marginVertical: hs(16),
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dot: {
        marginHorizontal: ws(4),
        borderRadius: 100,
    },
    designContiner: {
        height: 224,
        width: 224,
        borderRadius: 5,
        backgroundColor: '#F1F5F9',
        marginRight: 4,
        marginVertical: 5,
    },

    designContinerIamge: {
        height: 224,
        width: 224,
        borderRadius: 5,
        marginRight: 4,
        marginVertical: 5,
    },
    mainView: {
        margin: 20,
        width: 224,
    },
    blockView: {
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 18,
        height: 224,
        justifyContent: 'flex-end',
        padding: 16,
    },
    titleText: {
        fontWeight: '700',
        fontSize: 14,
        color: COLORS.COLOR_LIGHTBLACK,
        lineHeight: 22,
    },
    DescText: {
        fontWeight: '400',
        fontSize: 14,
        color: COLORS.COLOR_GRAY,
        lineHeight: 22,
        // width: 224,
    },
    priceBlock: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 4,
    },
    priceText: {
        fontWeight: '400',
        fontSize: 14,
        color: COLORS.COLOR_LIGHTBLACK,
        lineHeight: 22,
    },
    circle: {
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: COLORS.COLOR_LIGHTBLUE,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        left: 12,
        zIndex: 1,
    },
    iconStyle: {
        height: 24,
        width: 24,
    },
    leftIconPosition: {
        height: 30,
        width: 30,
        borderRadius: 20,
        position: 'relative',
        left: 10,
        zIndex: 1,
        marginTop: 112,
    },
    rightIconPosition: {
        height: 30,
        width: 30,
        borderRadius: 20,
        position: 'relative',
        right: 20,
        zIndex: 1,
        marginTop: 135,
    },
    textStyle: {
        fontWeight: '700',
        fontSize: 16,
        color: COLORS.COLOR_WHITE,
        lineHeight: 24,
    },
    btnStyle: {
        borderRadius: 8,
        height: 32,
    },
})
