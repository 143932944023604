import { StyleSheet } from 'react-native'
import { moderateScale, verticalScale } from '../../utils/screenRatio'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        borderTopRightRadius: 24,
    },
    shadowProp: {
        shadowOffset: { width: 2, height: 4 },
        shadowColor: COLORS.COLOR_SHADOW,
        shadowOpacity: 0.2,
        shadowRadius: 10,
        // borderBottomRightRadius: 24,
    },
    innerContainer: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 32,
        shadowOffset: { width: 2, height: 4 },
        shadowColor: COLORS.COLOR_SHADOW,
        shadowOpacity: 0.2,
        shadowRadius: 10,
        // paddingHorizontal: 15,
        // padding: 20
        // paddingVertical:25
    },
    iconContainer: {
        alignSelf: 'flex-end',
        marginHorizontal: 15,
        marginTop: 10,
    },
    crossIcon: {
        width: 24,
        height: 24,
    },
    horizontalLine: {
        height: 1,
        backgroundColor: COLORS.COLOR_GREY,
        marginTop: 15,
    },
    txtTitle: {
        fontSize: 24,
        color: COLORS.COLOR_LIGHTBLACK,
        fontWeight: '700',
        lineHeight: 32,
        marginHorizontal: 15,
    },
    txtDescription: {
        fontSize: 16,
        marginTop: 15,
        color: COLORS.COLOR_LIGHTBLACK,
        lineHeight: 24,
        fontWeight: '400',
        marginHorizontal: 15,
    },
    txtFooter: {
        fontSize: 16,
        marginTop: 40,
        color: COLORS.COLOR_MEDIUMBLUE,
        alignSelf: 'center',
        fontWeight: '700',
        marginHorizontal: 15,
    },
    // btnContainer: {
    //   marginVertical: moderateScale(20),
    //   marginHorizontal: moderateScale(10),
    // },
    txtDelAccount: {
        fontSize: 16,
        color: COLORS.COLOR_WHITE,
        lineHeight: 24,
        fontWeight: '700',
    },
    buttonStyle: {
        // borderRadius: 8,
        // height:56,
        marginVertical: 25,
        marginHorizontal: 15,
    },

    // style for Popover Input Component
    // mainContainerInput: {
    //   flex: 1,
    //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
    //   justifyContent: 'center',
    // },
    // innerContainerInput: {
    //   backgroundColor: COLORS.COLOR_WHITE,
    //   borderBottomRightRadius: 32,
    // },
    txtTitleInput: {
        marginHorizontal: 30,
        marginTop: 10,
        color: COLORS.COLOR_BLACK,
        fontSize: 24,
        lineHeight: 32,
        fontWeight: '700',
    },
    txtDescriptionInput: {
        fontSize: 16,
        marginTop: 15,
        color: COLORS.COLOR_LIGHTBLACK,
        lineHeight: 24,
        fontWeight: '400',
        marginHorizontal: 30,
    },
    // txtInputName: {
    //   marginHorizontal: moderateScale(10),
    //   fontSize: moderateScale(8),
    //   marginTop: moderateScale(15),
    //   color: COLORS.COLOR_GRAY,
    // },
    // txtInputEmail: {
    //   marginHorizontal: moderateScale(10),
    //   fontSize: moderateScale(8),
    //   marginTop: moderateScale(8),
    //   color: COLORS.COLOR_GRAY,
    // },
    inputContainer: {
        borderColor: COLORS.COLOR_LIGHTGRAY,
        marginTop: 10,
        marginHorizontal: 30,
    },
    viewBtnContainer: {
        flexDirection: 'row',
        alignSelf: 'flex-end',
        marginVertical: 15,
        marginHorizontal: 15,
        // marginRight: moderateScale(5),
        // marginBottom: moderateScale(15),
    },
    btnCancelDone: {
        marginHorizontal: 10,
        borderRadius: 8,
        width: 120,
        height: 56,
        alignSelf: 'center',
        backgroundColor: COLORS.COLOR_LIGHTPURPLE,
    },
    txtCancel: {
        color: COLORS.COLOR_MEDIUMBLUE,
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 24,
        fontWeight: '700',
    },
})
export default styles
