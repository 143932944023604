import React, { createContext } from 'react'

type ContextType = {
    newClick: boolean
    // Dumb component that checks if the provider is available or throws
    providerChecker: ({ children }) => React.ReactNode
}

export const BuilderContext = createContext({
    newClick: false,
    providerChecker: undefined,
} as ContextType)
