import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

export default StyleSheet.create({
    loaderContainer: {
        height: 10,
        width: '100%',
        backgroundColor: COLORS.COLOR_WHITE,
        borderRadius: 5,
        overflow: 'hidden',
    },
    loaderSmallContainer: {
        height: 10,
        width: '30%',
        backgroundColor: COLORS.COLOR_LIGHTGRAY,
        borderRadius: 5,
        overflow: 'hidden',
    },
    loaderProgress: {
        height: '100%',
        backgroundColor: COLORS.COLOR_PURPLE,
    },
    progressSpinner: {
        borderWidth: 8,
        borderColor: COLORS.COLOR_HIGHLIGHTBLUE,
        borderTopWidth: 8,
        borderTopColor: COLORS.COLOR_BLUE,
        borderRadius: 40,
        width: 80,
        height: 80,
    },

    progressDarkSpinner: {
        borderWidth: 8,
        borderColor: COLORS.COLOR_WHITE,
        borderTopWidth: 8,
        borderTopColor: COLORS.COLOR_GRAY,
        borderRadius: 40,
        width: 80,
        height: 80,
    },
    progressMainContainer: {
        backgroundColor: COLORS.COLOR_WHITE,
        alignItems: 'center',
        justifyContent: 'center',
        width: 300,
    },
    progressDarkThemeMainContainer: {
        backgroundColor: COLORS.COLOR_BLACK,
        alignItems: 'center',
        justifyContent: 'center',
        width: 300,
    },
})
