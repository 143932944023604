import { Dimensions, StyleSheet } from 'react-native'
import COLORS from '../../../utils/colors'

export default StyleSheet.create({
    mainView: {
        backgroundColor: '#F1F5F9',
        height: Dimensions.get('window').height,
        width: Dimensions.get('window').width,
        flexDirection: 'row',
    },
    sideBarContainer: {
        borderBottomRightRadius: 30,
        backgroundColor: COLORS.COLOR_WHITE,
        width: 350,
        paddingLeft: 20,
        paddingVertical: 20,
    },
    sideBartitleStyle: {
        color: '#64748B',
        fontSize: 20,
        marginBottom: 20,
    },
    listSubTitleStyle: {
        fontSize: 16,
        color: COLORS.COLOR_COOLGRAY,
    },
    listSubViewTitleStyle: {
        fontSize: 14,
        color: COLORS.COLOR_COOLGRAY,
    },
    listSubViewTitleTouchStyle: {
        fontWeight: '700',
        fontSize: 14,
        color: COLORS.COLOR_COOLGRAY,
    },
    listSubTitlePressStyle: {
        fontWeight: '400',
        fontSize: 16,
        color: COLORS.COLOR_GRAY,
    },
    listSubtTitleButtonStyle: {
        backgroundColor: COLORS.COLOR_WHITE,
        padding: 15,
        borderLeftColor: COLORS.COLOR_LIGHTGRAY,
        borderLeftWidth: 2,
    },
    listSubViewTitleButtonStyle: {
        backgroundColor: COLORS.COLOR_WHITE,
        padding: 15,
    },
    listSubtTitleButtonTouchStyle: {
        backgroundColor: COLORS.COLOR_WHITE,
        padding: 15,
        borderLeftColor: COLORS.COLOR_PURPLE,
        borderLeftWidth: 2,
    },
    listSubtTitleButtonPressStyle: {
        backgroundColor: '#F6F0FF',
        padding: 15,
        width: 300,
    },
    subViewStyle: {
        marginLeft: 30,
        height: 150,
    },
})
