import React, { Component } from 'react'
import {
    Pressable,
    View,
    Text,
    ViewStyle,
    TextStyle,
    PressableProps,
} from 'react-native'
import styles from './styles'
import COLORS from '../../utils/colors'

export interface SegmentedCtrlItemProps {
    /** height of the segmented control item */
    height?: number
    /** text style of the segmented control item */
    textStyle?: TextStyle
    /** item object of the segmented control */
    item: SegmentedCtrlItemData
    /** index position on the segmented control item list */
    index: number
    /** style for the segmented control item container */
    itemStyle?: ViewStyle
    /** selected item id on the segmented control item list */
    selectedId?: string
    /** selected background color for the selected segmented control item */
    itemSelectdBgColor?: string
    /** selected text color for the selected segmented control item */
    itemSelectdTxtColor?: string
}

export interface SegmentedCtrlItemData extends PressableProps {
    id: string
    name: string
    icon?: React.ReactNode
    selectedIcon?: React.ReactNode
}

class SegmentedCtrlItem extends Component<SegmentedCtrlItemProps> {
    render() {
        const {
            height,
            textStyle,
            item,
            index,
            itemStyle,
            selectedId,
            itemSelectdBgColor = '',
            itemSelectdTxtColor = '',
            ...otherPressableProps
        } = this.props

        return (
            <Pressable {...otherPressableProps}>
                <View
                    style={[
                        styles.button,
                        { height: height },
                        { borderWidth: selectedId === item?.id ? 1 : 0 },
                        {
                            borderColor: itemStyle?.borderColor
                                ? itemStyle.borderColor
                                : COLORS.COLOR_BLUE,
                        },
                        {
                            backgroundColor:
                                selectedId === item?.id
                                    ? itemSelectdBgColor !== ''
                                        ? itemSelectdBgColor
                                        : COLORS.COLOR_SEGMNTD
                                    : COLORS.COLOR_INFO,
                        },
                        { borderRadius: selectedId === item?.id ? 1000 : 0 },
                        itemStyle,
                    ]}
                >
                    {item?.icon &&
                        (selectedId === item?.id
                            ? item.selectedIcon
                            : item.icon)}
                    <Text
                        style={[
                            styles.text,
                            {
                                color:
                                    selectedId === item?.id
                                        ? itemSelectdTxtColor !== ''
                                            ? itemSelectdTxtColor
                                            : COLORS.COLOR_MEDIUMBLUE
                                        : COLORS.COLOR_GRAY,
                            },
                            textStyle,
                        ]}
                    >
                        {item?.name}
                    </Text>
                </View>
            </Pressable>
        )
    }
}

export default SegmentedCtrlItem
