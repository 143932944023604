import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

export const styles = StyleSheet.create({
    containerStyle: {
        backgroundColor: COLORS.COLOR_WHITE,
        borderRadius: 8,
        padding: 16,
    },
    productImage: {
        paddingHorizontal: 10,
    },
    productDetailContainer: {
        paddingTop: 8,
    },
    titleTextStyle: {
        fontSize: 14,
        fontWeight: '700',
        lineHeight: 22,
        color: COLORS.COLOR_LIGHTBLACK,
    },
    priceTextStyle: {
        paddingTop: 8,
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 22,
        color: COLORS.COLOR_LIGHTBLACK,
    },
    descriptionTextStyle: {
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 22,
        color: COLORS.COLOR_GRAY,
    },
    shadowProp: {
        shadowOffset: { width: 2, height: 4 },
        shadowColor: COLORS.COLOR_SHADOW,
        shadowOpacity: 0.2,
        shadowRadius: 10,
    },

    // Loading state
    topRightIconLoading: {
        alignSelf: 'flex-end',
        width: 40,
        height: 40,
        backgroundColor: COLORS.COLOR_LIGHTPURPLE,
        borderRadius: 8,
        position: 'absolute',
        top: 10,
        zIndex: 1,
    },
    productImageLoading: {
        width: '100%',
        height: 155,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    titleLoading: {
        width: '70%',
        padding: 10,
        backgroundColor: COLORS.COLOR_LIGHTGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    descriptionLoading: {
        width: '100%',
        padding: 10,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    descriptionLoadingSmall: {
        maxWidth: 180,
    },
    priceLoading: {
        width: '40%',
        padding: 10,
        backgroundColor: COLORS.COLOR_LIGHTGRAY,
        borderRadius: 8,
        marginTop: 15,
    },

    // Mobile
    containerStyleMobile: {
        maxWidth: 160,
        height: 'fit',
        backgroundColor: COLORS.COLOR_WHITE,
        borderRadius: 8,
        padding: 16,
    },
    productImageMobile: {},
    productDetailContainerMobile: {
        paddingTop: 8,
    },
    gradientOverlay: {
        height: '100%',
    },
})
