import React, { Component } from 'react'
import { Image, View, Pressable, Text } from 'react-native'
import styles from './styles_deprecated'
import Button from '../Button/Button'
import STRINGS from '../../utils/strings'
import STRINGS_TESTCASE from '../../utils/stringsTestCase'
import { PopoverProps } from '.'

/**
 * @deprecated Since version 1.1.4 - Use Popover instead
 */
export interface PopoverConfirmComptProps extends PopoverProps {
    /** style for the cancel button */
    btnCancelStyle?: {}
    /** text style for the cancel button */
    txtCancelStyle?: {}
    /** style for the done button */
    btnDoneStyle?: {}
    /** text style for the done button */
    txtDoneStyle?: {}
    /** array of text inputs */
    textInputs?: Array<object>
}

interface PopoverState {
    isModalVisible: boolean
}

class PopoverConfirmCompt extends Component<
    PopoverConfirmComptProps,
    PopoverState
> {
    constructor(props: PopoverConfirmComptProps) {
        super(props)
        this.state = {
            isModalVisible: false,
        }
    }

    onPressCloseIcon = () => {}

    onPressCancel = () => {}

    onPressDone = () => {}

    render() {
        const {
            containerStyle,
            titleText = STRINGS.DEFAULT_TXT_POPOVR_1,
            titleStyle,
            descriptionText = STRINGS.DEFAULT_TXT_POPOVR_2,
            descriptionStyle,
            btnCancelStyle,
            txtCancelStyle,
            btnDoneStyle,
            txtDoneStyle,
            closeIcon,
            gradientColor1,
            gradientColor2,
            onPressCloseIcon,
            onPressCancel,
            onPressDone,
        } = this.props

        return (
            <View style={[styles.innerContainer, containerStyle]}>
                <Pressable
                    onPress={onPressCloseIcon}
                    testID={STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON}
                    style={styles.iconContainer}
                >
                    {closeIcon ? (
                        closeIcon
                    ) : (
                        <Image
                            source={{ uri: 'https://i.imgur.com/amHs6X5.png' }}
                            style={[styles.crossIcon]}
                        />
                    )}
                </Pressable>
                <Text style={[styles.txtTitle, titleStyle]}>{titleText}</Text>
                <Text style={[styles.txtDescription, descriptionStyle]}>
                    {descriptionText}
                </Text>
                <View style={styles.horizontalLine} />
                <View style={styles.viewBtnContainer}>
                    <Button
                        style={{ ...styles.btnCancelDone, ...btnCancelStyle }}
                        textStyle={[styles.txtCancel, txtCancelStyle]}
                        onPress={onPressCancel}
                        text={STRINGS.CANCEL}
                    />
                    <Button
                        style={btnDoneStyle}
                        textStyle={{ ...styles.txtDelAccount, ...txtDoneStyle }}
                        onPress={onPressDone}
                        gradientColors={[gradientColor1, gradientColor2]}
                        text={STRINGS.DONE}
                    />
                </View>
            </View>
        )
    }
}

export default PopoverConfirmCompt
